.contacts-section {
  --icon-color: #{$black};
  --icon-hover-color: #{$red};
  .left-col {
    flex: 0 0 38.8%;
  }
  .right-col {
    flex: 0 0 49%;
  }
  .desc {
    padding-bottom: r(60);
    color: $gray;
    border-bottom: 2px solid $light-gray;
  }
  .bottom {
    padding-top: r(21);
  }
  .map {
    height: r(360);
  }
  .contact-name {
    margin-bottom: r(24);
  }
  .social-contact {
    &:not(:last-child) {
      margin-right: r(40);
    }
  }
  .contacts {
    display: grid;
    grid-template-columns: 30% max-content;
    justify-content: space-between;
    gap: r(60);
  }
}

@media (max-width: 1279px) {
  .contacts-section {
    .title {
      margin-bottom: r(20);
    }
    .desc {
      padding-bottom: r(40);
    }
    .bottom {
      padding: r(40) 0 0;
    }
    .contacts {
      grid-template-columns: repeat(2, 1fr);
      gap: r(42) r(11);
    }
    .right-col {
      width: 100%;
      flex: 0 0 auto;
    }
    .contact-name {
      margin-bottom: r(10);
    }
    .social-contact {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    .pinterest-icon {
      .icon {
        width: r(21);
        height: r(21);
      }
    }
    .instagram-icon {
      .icon {
        width: r(21);
        height: r(21);
      }
    }
    .telegram-icon {
      .icon {
        width: r(21);
        height: r(21);
      }
    }
    .vk-icon {
      .icon {
        width: r(21);
        height: r(21);
      }
    }
    .left-col {
      flex: 0 0 auto;
    }
    .map {
      height: r(275);
    }
    .map-col {
      margin: r(41) 0 0;
      order: 1;
    }
  }
}
