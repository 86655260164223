.services-section {
  padding: r(120) 0;
  position: relative;
  background: $black;
  color: $white;
  .services-col {
    flex: 0 0 49%;
  }
  .service {
    padding: 0 r(32) 0;
    position: relative;
    z-index: 1;
    --link-hover-color: #{$white};
    .text {
      margin-top: r(26);
      color: #c2c2c2;
    }
    &-wrap {
      padding: r(42) 0;
      border-bottom: 1px solid rgba(233, 232, 233, 0.42);
    }
    .bg {
      width: auto;
      left: r(32);
      right: r(32);
      opacity: 0;
      transition-duration: $tr-time;
      transition-property: left, right;
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba($black, 0.8);
      }
    }
    .side {
      position: absolute;
      top: 0;
      z-index: 1;
      width: r(16);
      height: 100%;
      .line {
        height: 100%;
        width: 50%;
        margin-left: auto;
        background: $red;
      }
      &::before,
      &::after {
        content: "";
        background: $red;
        position: absolute;
        height: r(8);
        left: 0;
        right: 0;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
      &.left {
        left: 0;
        transform: rotate(180deg);
      }
      &.right {
        right: 0;
      }
    }
    &:hover {
      .bg {
        left: 0;
        right: 0;
        opacity: 1;
      }
    }
  }
  .decor-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: r(628);
    height: r(719);
    object-fit: contain;
    object-position: bottom left;
  }
}

@media (max-width: 1279px) {
  .services-section {
    padding: r(42) 0 0;
    .decor-img {
      position: static;
      height: r(285);
      width: 100%;
      margin-top: r(54);
      object-fit: contain;
      object-position: center;
    }
    .service {
      padding: 0;
      .name {
        font-size: r(21);
      }
      .text {
        margin-top: r(10);
      }
      .side {
        width: r(12);
        &.left {
          left: r(-12);
        }
        &.right {
          right: r(-12);
        }
        &::before,
        &::after {
          height: r(6);
        }
      }
    }
    .service-wrap {
      padding: r(30) 0;
    }
    .title {
      margin-bottom: r(10);
    }
  }
}
