.director-section {
  position: relative;
  padding: r(120) 0;
  .title {
    margin-bottom: r(60);
  }
  .text-col {
    align-self: center;
    flex: 0 0 49%;
  }
  .img-col {
    flex: 0 0 22%;
  }
  .name {
    font-weight: 600;
  }
  .position {
    font-weight: 300;
  }
  .content-text {
    margin-bottom: r(60);
  }
  .img-col {
    flex: 0 0 38.8%;
  }
  .img {
    width: 100%;
  }
  .decor-img {
    width: r(557);
    height: r(425);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 1279px) {
  .director-section {
    padding: r(60) 0;
    .text-col {
      order: 1;
    }
    .img-col {
      margin-bottom: r(30);
    }
    .title {
      margin-bottom: r(20);
      font-size: r(18);
    }
    .decor-img {
      display: none;
    }
    .content-text {
      margin-bottom: r(20);
    }
  }
}
