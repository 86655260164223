.black-section {
  position: relative;
  padding: r(40) 0 r(60);
  background: $black;
  color: $white;
  --breadcrumb-color: #{rgba($white, 0.5)};
  --breadcrumb-active-color: #{$white};
  .breadcrumbs {
    margin-bottom: r(60);
  }
  .container {
    display: flex;
  }
  .text-col {
    flex: 0 0 50%;
    position: relative;
    margin-left: auto;
  }
  .logo {
    width: r(450);
    height: r(63);
    margin-bottom: r(60);
    fill: $white;
  }
  .content-text {
    margin-bottom: r(89);
  }
  .numbers {
    border-bottom: 2px solid $white;
    li {
      display: flex;
      justify-content: space-between;
      padding: r(24) 0;
      border-top: 2px solid $white;
    }
  }
  .img {
    height: 92%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .text-col {
    flex: 0 0 33.9%;
  }
}

@media (max-width: 1279px) {
  .black-section {
    padding: r(24) 0 r(40);
    position: relative;
    .container {
      grid-template-columns: 100%;
    }
    .text-col {
      width: 85.9%;
      margin: 0 auto;
    }
    .img {
      height: auto;
      width: 100%;
      opacity: 0.7;
    }
    .logo {
      width: r(279);
      height: r(39);
      margin-bottom: r(20);
    }
    .content-text {
      margin-bottom: r(30);
    }
    .breadcrumbs {
      margin-bottom: r(40);
    }
    .numbers {
      border-bottom-width: 1px;
      li {
        padding: r(14) 0;
        border-top-width: 1px;
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: rgba(233, 232, 233, 0.06);
    }
    &::before {
      left: r(25);
    }
    &::after {
      right: r(25);
    }
  }
}
