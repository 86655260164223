.about-project {
  display: flex;
  justify-content: space-between;
  .text-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .title {
      margin-bottom: r(30);
    }
    .block-name {
      padding: r(20) 0;
      margin-bottom: auto;
      border-top: 1px solid $light-gray;
    }
  }
  .img-col {
    .img {
      width: 100%;
    }
  }
  &.full-width {
    .text-col {
      flex: 0 0 40.7%;
    }
    .img-col {
      flex: 0 0 57.65%;
    }
  }
  &.img-left {
    .text-col,
    .img-col {
      flex: 0 0 40.7%;
    }
    .img-col {
      margin-right: r(180);
    }
    .text-col {
      order: 1;
      margin-right: r(150);
    }
  }
  &.center-img-right {
    justify-content: center;
    .text-col {
      margin-right: r(180);
      flex: 0 0 32.2%;
    }
    .img-col {
      flex: 0 0 40.7%;
    }
  }
  &.center-img-left {
    justify-content: center;
    .text-col {
      margin-left: r(180);
      flex: 0 0 40.7%;
      order: 1;
    }
    .img-col {
      flex: 0 0 32.2%;
    }
  }
  &:not(:last-child) {
    margin-bottom: r(220);
  }
}

@media (max-width: 1279px) {
  .about-project {
    position: relative;
    flex-direction: column;
    margin: 0 r(-25);
    padding: 0 r(25);
    .text-col {
      order: 1;
      .title {
        margin-bottom: r(10);
      }
      .block-name {
        position: absolute;
        top: 0;
        width: 50%;
        padding: r(10) r(25) 0;
        font-size: r(12);
      }
    }
    &.full-width,
    &.img-left,
    &.center-img-right,
    &.center-img-left {
      align-items: flex-end;
      .img-col {
        position: relative;
        z-index: 1;
      }
      .text-col {
        padding: r(30) 0 0;
      }
      .text-col,
      .img-col {
        flex: 0 0 auto;
        width: 74.2%;
      }
    }
    &.full-width,
    &.center-img-right {
      .block-name {
        left: 0;
      }
    }
    &.img-left {
      .block-name {
        right: 0;
        text-align: right;
      }
      .img-col,
      .text-col {
        margin: 0 auto 0 0;
      }
    }
    &.center-img-right {
      .text-col {
        margin: 0;
      }
    }
    &.center-img-left {
      .block-name {
        right: 0;
        text-align: right;
      }
      .text-col,
      .img-col {
        margin-right: auto;
      }
      .text-col {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
