.categories-btns {
  display: flex;
  flex-wrap: wrap;
  .btn {
    flex: 0 0 auto;
    &:not(:last-child) {
      margin-right: r(38);
    }
  }
}

@media (max-width: 1279px) {
  .categories-btns {
    margin: 0 r(-12) r(-12) 0;
    .btn {
      margin-bottom: r(12);
      &:not(:last-child) {
        margin-right: r(12);
      }
    }
  }
}
