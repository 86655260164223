.patronage-img-blocks {
  display: flex;
  justify-content: space-between;
  .left-col {
    flex: 0 0 32.2%;
  }
  .right-col {
    flex: 0 0 66.1%;
    margin-left: auto;
  }
  .img {
    width: 100%;
    height: r(700);
    margin-bottom: r(40);
  }
  .col-text {
    width: 74.4%;
  }
  &:not(:last-child) {
    margin-bottom: r(120);
  }
}

@media (max-width: 1279px) {
  .patronage-img-blocks {
    display: block;
    .left-col {
      margin-bottom: r(60);
    }
    .right-col {
      margin: 0;
    }
    .img {
      height: auto;
      margin-bottom: r(30);
    }
    .col-text {
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: r(60);
    }
  }
}
