.vk-icon {
  .icon {
    width: r(46);
    height: r(30);
    fill: var(--icon-color);
  }
  &:hover {
    .icon {
      fill: var(--icon-hover-color);
    }
  }
}

@media (max-width: 1279px) {
  .vk-icon {
    .icon {
      width: r(36);
      height: r(22);
    }
  }
}
