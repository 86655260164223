:root {
  font-size: calcFluid(12.8, 15, 320, 375);
  --link-hover-color: #ff4a98;
  @media (min-width: 375px) {
    font-size: 15px;
  }
  @media (min-width: 1280px) {
    font-size: calcFluid(10, 15, 1280, 1920);
  }
  @media (min-width: 1920px) {
    font-size: 15px;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  --link-hover-color: #{$red};
  --form-status-icon-color: #{$black};
  --form-status-scale: 0.8;
}
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
}
button {
  border: 0;
  color: inherit;
  background: transparent;
  cursor: pointer;
}
button,
input,
textarea {
  font-family: inherit;
}
input {
  min-width: 0;
  font-size: inherit;
  border-radius: 0;
}
input[type="file" i],
input[type="file" i]::-webkit-file-upload-button {
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
a {
  color: inherit;
  text-decoration: none;
  transition: color $tr-time;
  &:hover {
    color: var(--link-hover-color);
    text-decoration: none;
  }
}
svg {
  vertical-align: middle;
  transition: fill $tr-time, stroke $tr-time;
}
img {
  user-select: none;
  object-fit: cover;
}
img,
iframe,
svg,
picture {
  display: block;
}
iframe {
  border: 0;
}
h1,
h2,
h3 {
  font-weight: 400;
  font-size: inherit;
}
:focus {
  outline: none;
}

@media (max-width: 1279px) {
  .menu-open {
    display: none !important;
  }
}
