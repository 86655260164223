.form {
  &-input {
    display: block;
    width: 100%;
    height: r(64);
    padding: r(17) r(30) 0;
    font-size: r(20);
    color: var(--form-text-color);
    background: var(--form-bg-color);
    border: 1px solid var(--form-border-color);
    transition: border-color $tr-time;
    &-underline {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
    &::placeholder {
      opacity: 0;
    }
    &.error {
      border-color: var(--form-error-color) !important;
    }
    &:focus,
    &:not(:placeholder-shown) {
      + .form-placeholder {
        transform: translateY(-50%) scale(0.6);
        color: var(--form-placeholder-active-color);
      }
    }
    &--textarea {
      height: r(170);
      padding: r(25) r(30) 0;
      resize: none;
      + .form-placeholder {
        bottom: unset;
        top: r(20);
      }
    }
  }
  &-label {
    display: block;
    &-text {
      display: block;
      margin-bottom: r(14);
      color: var(--form-text-color);
    }
  }
  &-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: r(30);
    height: 1em;
    margin: auto;
    color: var(--form-placeholder-color);
    font-size: r(20);
    line-height: 1;
    letter-spacing: r(3.6);
    text-transform: uppercase;
    pointer-events: none;
    transform-origin: left top;
    transition-property: transform;
    transition-duration: $tr-time;
  }
  &-input-wrap {
    position: relative;
  }
  &-error {
    margin-top: r(8);
    font-size: r(14);
    line-height: 1.39;
    color: var(--form-error-color);
  }
}
.personal {
  color: var(--form-personal-color);
  &__link {
    color: var(--form-personal-link-color);
    text-decoration: underline;
  }
}

.ok-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: r(42);
  height: r(29);
  margin: auto;
  transform: scale(var(--form-status-scale));
  fill: var(--form-status-icon-color);
}

@media (max-width: 1279px) {
  .personal {
    font-size: r(12) !important;
  }
  .form {
    &-input {
      padding: r(8) r(14) 0;
      height: r(35);
      font-size: r(12);
      &--textarea {
        height: r(155);
        & + .form-placeholder {
          top: r(8);
        }
      }
    }
    &-placeholder {
      left: r(14);
      font-size: r(14);
      letter-spacing: r(2.5);
    }
  }
}
