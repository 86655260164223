*,
::before,
::after {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}
::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 94.8%;
  padding-left: 1.67rem;
  padding-right: 1.67rem;
}
.bigtext,
.text {
  font-size: 0.93rem;
  line-height: 1.4;
}
.heading-1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.3;
}
.heading-2 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
}
.heading-3 {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
}
.heading-4 {
  font-size: 1.06rem;
  font-weight: 500;
  line-height: 1.3;
}
.large-title {
  font-size: 4.93rem;
  font-weight: 500;
  line-height: 1.3;
}
@media (max-width: 1279.9px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1280px) {
  .bigtext {
    font-size: 1.466rem;
  }
  .heading-1 {
    font-size: 4.26rem;
  }
  .heading-2 {
    font-size: 2.4rem;
  }
  .heading-3 {
    font-size: 2.13rem;
  }
  .heading-4 {
    font-size: 1.6rem;
  }
  .text {
    font-size: 1.2rem;
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  inset: 0;
}
.z-\[-1\] {
  z-index: -1;
}
.grid {
  display: grid;
}
.auto-cols-max {
  grid-auto-columns: max-content;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.mx-\[\.5rem\] {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-\[1\.33rem\] {
  margin-bottom: 1.33rem;
}
.mb-\[1\.8rem\] {
  margin-bottom: 1.8rem;
}
.mb-\[2\.53rem\] {
  margin-bottom: 2.53rem;
}
.mb-\[2\.67rem\] {
  margin-bottom: 2.67rem;
}
.mb-\[2rem\] {
  margin-bottom: 2rem;
}
.mb-\[4rem\] {
  margin-bottom: 4rem;
}
.mr-auto {
  margin-right: auto;
}
.mt-\[0\.8rem\] {
  margin-top: 0.8rem;
}
.mt-\[1rem\] {
  margin-top: 1rem;
}
.mt-\[2\.67rem\] {
  margin-top: 2.67rem;
}
.mt-\[2rem\] {
  margin-top: 2rem;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.h-\[2\.86rem\] {
  height: 2.86rem;
}
.h-\[7rem\] {
  height: 7rem;
}
.h-full {
  height: 100%;
}
.h2 {
  height: 0.5rem;
}
.h4 {
  height: 1rem;
}
.min-h-screen {
  min-height: 100vh;
}
.w-\[10\.46rem\] {
  width: 10.46rem;
}
.w-\[25\.5rem\] {
  width: 25.5rem;
}
.w-\[60\%\] {
  width: 60%;
}
.w-\[74\.6\%\] {
  width: 74.6%;
}
.w-74\.5\% {
  width: 74.5%;
}
.w-75\% {
  width: 75%;
}
.w-83\% {
  width: 83%;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow-1 {
  flex-grow: 1;
}
.basis-\[48\.3\%\] {
  flex-basis: 48.3%;
}
.basis-auto {
  flex-basis: auto;
}
.basis-full {
  flex-basis: 100%;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.self-center {
  align-self: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-\[0\.73rem\] {
  gap: 0.73rem;
}
.gap-\[4rem\] {
  gap: 4rem;
}
.gap-x-\[2\.53rem\] {
  column-gap: 2.53rem;
}
.gap-y-\[1rem\] {
  row-gap: 1rem;
}
.gap-y-\[4rem\] {
  row-gap: 4rem;
}
.divide-x-\[2px\] > :not([hidden]) ~ :not([hidden]) {
  --un-divide-x-reverse: 0;
  border-left-width: calc(2px * calc(1 - var(--un-divide-x-reverse)));
  border-right-width: calc(2px * var(--un-divide-x-reverse));
}
.divide-y-\[2px\] > :not([hidden]) ~ :not([hidden]) {
  --un-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--un-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--un-divide-y-reverse));
}
.divide-lightgray > :not([hidden]) ~ :not([hidden]) {
  --un-divide-opacity: 1;
  border-color: rgb(230 230 230 / var(--un-divide-opacity));
}
.border-solid,
.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.border-\[0\.5px\] {
  border-width: 0.5px;
}
.border-\[1px\] {
  border-width: 1px;
}
.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}
.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.border-\[\#E6E6E6\],
.border-lightgray {
  --un-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--un-border-opacity));
}
.bg-black {
  --un-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--un-bg-opacity));
}
.fill-black {
  --un-fill-opacity: 1;
  fill: rgb(28 28 28 / var(--un-fill-opacity));
}
.fill-red {
  --un-fill-opacity: 1;
  fill: rgb(216 46 46 / var(--un-fill-opacity));
}
.pt-\[0\.4rem\] {
  padding-top: 0.4rem;
}
.pt-\[1\.27rem\] {
  padding-top: 1.27rem;
}
.text-center {
  text-align: center;
}
.color-gray,
.text-gray {
  --un-text-opacity: 1;
  color: rgb(99 99 99 / var(--un-text-opacity));
}
.text-lightgray {
  --un-text-opacity: 1;
  color: rgb(230 230 230 / var(--un-text-opacity));
}
.color-black {
  --un-text-opacity: 1;
  color: rgb(28 28 28 / var(--un-text-opacity));
}
.color-white {
  --un-text-opacity: 1;
  color: rgb(245 245 245 / var(--un-text-opacity));
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-sans {
  font-family: "Montserrat", sans-serif;
}
.font-serif {
  font-family: "Cormorant Infant", serif;
}
.uppercase {
  text-transform: uppercase;
}
@media (max-width: 1279.9px) {
  .max-xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .max-xl\:mt-\[2rem\] {
    margin-top: 2rem;
  }
  .max-xl\:mt-\[4rem\] {
    margin-top: 4rem;
  }
  .max-xl\:hidden {
    display: none;
  }
  .max-xl\:w-full {
    width: 100%;
  }
  .max-xl\:flex-col {
    flex-direction: column;
  }
  .max-xl\:justify-between {
    justify-content: space-between;
  }
  .max-xl\:text-\[1\.2rem\] {
    font-size: 1.2rem;
  }
}
@media (min-width: 1280px) {
  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xl\:mx-\[1\.33rem\] {
    margin-left: 1.33rem;
    margin-right: 1.33rem;
  }
  .xl\:mb-\[2\.67rem\] {
    margin-bottom: 2.67rem;
  }
  .xl\:mb-\[4rem\] {
    margin-bottom: 4rem;
  }
  .xl\:ml-\[10rem\] {
    margin-left: 10rem;
  }
  .xl\:mr-\[10rem\] {
    margin-right: 10rem;
  }
  .xl\:mt-\[2\.67rem\] {
    margin-top: 2.67rem;
  }
  .xl\:mt-\[2rem\] {
    margin-top: 2rem;
  }
  .xl\:mt-\[4rem\] {
    margin-top: 4rem;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:h-full {
    height: 100%;
  }
  .xl\:max-w-\[40\%\] {
    max-width: 40%;
  }
  .xl\:max-w-\[64\%\] {
    max-width: 64%;
  }
  .xl\:max-w-\[69\.6\%\] {
    max-width: 69.6%;
  }
  .xl\:w-\[66\.1\%\] {
    width: 66.1%;
  }
  .xl\:w-\[83\%\],
  .xl\:w-83\% {
    width: 83%;
  }
  .xl\:w-20\.4\% {
    width: 20.4%;
  }
  .xl\:w-42\% {
    width: 42%;
  }
  .xl\:w-49\% {
    width: 49%;
  }
  .xl\:w-56\% {
    width: 56%;
  }
  .xl\:w-60\% {
    width: 60%;
  }
  .xl\:w-69\.45\% {
    width: 69.45%;
  }
  .xl\:w-80\% {
    width: 80%;
  }
  .xl\:basis-\[28\.6\%\] {
    flex-basis: 28.6%;
  }
  .xl\:basis-\[38\.8\%\] {
    flex-basis: 38.8%;
  }
  .xl\:basis-\[63\%\] {
    flex-basis: 63%;
  }
  .xl\:gap-\[4\.53rem\] {
    gap: 4.53rem;
  }
  .xl\:gap-y-\[10\.67rem\] {
    row-gap: 10.67rem;
  }
  .xl\:border-x-\[2px\] {
    border-left-width: 2px;
    border-right-width: 2px;
  }
  .xl\:border-y-\[0\.5px\] {
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;
  }
  .xl\:border-y-\[1px\] {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .xl\:pb-\[1rem\] {
    padding-bottom: 1rem;
  }
  .xl\:pt-\[2rem\] {
    padding-top: 2rem;
  }
}
