.content-slider {
	.img-wrap {
	}
	.main-swiper {
		.img-wrap {
			display: block;
			position: relative;
			padding-bottom: 55.9%;
			overflow: hidden;
		}
		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.thumbs-swiper {
		.img-wrap {
			position: relative;
			padding-bottom: 55.2%;
			overflow: hidden;
			.img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.swiper-slide {
			cursor: pointer;
		}
		.swiper-slide-thumb-active {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				border: 4px solid $red;
			}
		}
	}
	.slider-arrow {
		.icon {
			width: r(9);
			height: r(15);
		}
		&.next {
			.icon {
				transform: rotate(180deg);
			}
		}
	}
}

@media (max-width: 1279px) {
	.content-slider {
		.img-wrap {
		}
	}
}
