$time: 0.6s;
.main-screen {
  height: r(786);
  display: flex;
  background: $black;
  overflow: hidden;
  .col {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: var(--width);
    z-index: 1;
    transition: width $time;
  }
  .name {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: r(17);
    transform-origin: center bottom;
    font-size: r(24);
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: -1.5px;
    text-align: center;
    color: $white;
    white-space: nowrap;
    transition: transform $time;
  }
  .img-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($black, 0.2);
    }
  }
  .logo {
    width: 92.2%;
    height: r(246);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    fill: #888;
  }
}

@media (min-width: 1280px) {
  .main-screen {
    .col {
      &:not(:first-child) {
        border-left: 2px solid rgba(233, 232, 233, 0.06);
      }
      &:hover {
        width: 40%;
        .img-wrap {
          opacity: 1;
        }
        .name {
          transform: translateX(50%) scale(1.52);
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .main-screen {
    height: auto;
    flex-direction: column;
    border-top: 1px solid rgba(233, 232, 233, 0.06);
    .logo,
    .img-wrap {
      display: none;
    }
    .name {
      position: static;
      font-size: r(19);
      font-weight: 500;
      letter-spacing: r(-0.95);
      transform: none;
    }
    .col {
      width: 100%;
      padding: r(37) 0;
      border-bottom: 1px solid rgba(233, 232, 233, 0.06);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: rgba(233, 232, 233, 0.06);
    }
    &::before {
      left: r(25);
    }
    &::after {
      right: r(25);
    }
  }
}
