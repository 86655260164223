.blog-section {
  padding: r(120) 0 r(120);
  overflow: hidden;
  .title-row {
    margin-bottom: r(60);
    margin-left: r(150);
  }
  .swiper {
    overflow: visible;
  }
  + .partners-section {
    padding-top: 0;
  }
}

@media (max-width: 1279px) {
  .blog-section {
    padding: r(60) 0;
  }
}
