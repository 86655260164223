.menu-open {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: r(40);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: $black;
  border-right: 1px solid rgba(233, 232, 233, 0.06);
  &::before,
  &::after {
    content: "";
    width: r(2);
    height: r(98);
    margin: 0 r(3);
    background: $white;
    transform-origin: left bottom;
    transition: transform $tr-time;
  }
  &.open {
    &::after {
      transform: rotate(5deg);
    }
  }
}
