@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap");

@import "./uno-utils.scss";

@import "./base/fonts.scss";
@import "./base/variables.scss";
@import "./base/functions.scss";
@import "./base/reset.scss";
@import "./base/utils.scss";

@import "./forms/forms.scss";
@import "./forms/preloader.scss";
@import "./forms/default-form-colors.scss";

@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/menu-open.scss";
@import "./components/main-menu.scss";
@import "./components/blog-card.scss";
@import "./components/whatsapp-icon.scss";
@import "./components/pinterest-icon.scss";
@import "./components/instagram-icon.scss";
@import "./components/telegram-icon.scss";
@import "./components/vk-icon.scss";
@import "./components/page-head.scss";
@import "./components/breadcrumbs.scss";
@import "./components/contacts-section.scss";
@import "./components/default-map-marker.scss";
@import "./components/categories-list.scss";
@import "./components/post-row.scss";
@import "./components/accordion.scss";
@import "./components/services-list.scss";
@import "./components/image-page-head.scss";
@import "./components/text-page-head.scss";
@import "./components/case-section.scss";
@import "./components/categories-btns.scss";
@import "./components/post-card.scss";
@import "./components/vacancy.scss";
@import "./components/project-page.scss";
@import "./components/about-project.scss";
@import "./components/range-slider.scss";
@import "./components/search-form.scss";
@import "./components/search-page.scss";
@import "./components/order-project-btn.scss";
@import "./components/patronage-page.scss";
@import "./components/video-review.scss";
@import "./components/text-review.scss";
@import "./components/kuula-section.scss";

@import "./text-page/common-content.scss";
@import "./text-page/content-text.scss";
@import "./text-page/content-image.scss";
@import "./text-page/content-file-row.scss";
@import "./text-page/content-title-underline.scss";
@import "./text-page/content-quote.scss";
@import "./text-page/content-gallery.scss";
@import "./text-page/content-gallery-item.scss";
@import "./text-page/content-slider.scss";
@import "./text-page/content-video-text.scss";
@import "./text-page/content-text-image.scss";
@import "./text-page/content-kuula-embed.scss";

@import "./blocks/main-screen.scss";
@import "./blocks/second-screen.scss";
@import "./blocks/services-section.scss";
@import "./blocks/projects-section.scss";
@import "./blocks/director-section.scss";
@import "./blocks/blog-section.scss";
@import "./blocks/partners-section.scss";
@import "./blocks/feedback-section.scss";
@import "./blocks/services-tree";
@import "./blocks/service-description.scss";
@import "./blocks/vacancies-section.scss";
@import "./blocks/black-section.scss";
@import "./blocks/work-steps-section.scss";
@import "./blocks/additional-posts.scss";
@import "./blocks/order-project-section.scss";
@import "./blocks/patronage-about.scss";
@import "./blocks/patronage-img-blocks.scss";
@import "./blocks/patronage-gallery-grid.scss";
@import "./blocks/review-section.scss";
