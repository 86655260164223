.project-page {
  .info-row {
    display: flex;
    margin-bottom: r(5);
    .date {
      margin-left: auto;
    }
  }
  .detail-picture {
    width: 100%;
    margin-bottom: r(60);
  }
  .title {
    width: 66.1%;
    margin-bottom: r(40);
    font-size: r(58);
  }
  .description-row {
    display: flex;
    align-items: flex-end;
    .description {
      flex: 0 0 66.1%;
    }
    .duration {
      margin-left: auto;
      font-size: r(24);
    }
  }
  .project-numbers {
    display: flex;
    margin-top: r(120);
    .value {
      &:not(:last-child) {
        margin-right: r(118);
      }
    }
    .name {
      margin-bottom: r(12);
    }
    .number {
      font-size: r(100);
      font-weight: 500;
      line-height: 0.96;
    }
  }
  .project-content {
    margin: r(180) 0 r(100);
  }
}

@media (max-width: 1279px) {
  .project-page {
    .title {
      margin-bottom: r(12);
    }
    .project-content {
      margin: r(69) 0 0;
    }
    .detail-picture {
      margin-bottom: r(30);
    }
    .project-numbers {
      margin: r(60) 0 0;
      flex-direction: column;
      .value {
        &:not(:last-child) {
          margin: 0 0 r(20);
        }
      }
      .name {
        margin-bottom: r(9);
      }
      .number {
        font-size: r(59);
      }
    }
    .title {
      width: 100%;
      font-size: r(21);
    }
    .info-row {
      margin-bottom: r(20);
    }
    .description-row {
      flex-direction: column;
      align-items: flex-start;
      .duration {
        margin: r(20) 0 0;
        font-size: r(12);
      }
    }
    .about-project {
      &:not(:last-child) {
        margin-bottom: r(60);
      }
    }
  }
}
