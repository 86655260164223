.range-slider {
  .noUi {
    &-marker {
      display: none;
    }
    &-horizontal {
      height: auto;
      .noUi-handle {
        width: r(36);
        height: r(36);
        right: r(-18);
        top: r(-6);
        &::before,
        &::after {
          display: none;
        }
      }
    }
    &-handle {
      background: $white;
      border: 5px solid $black;
      border-radius: 50%;
      box-shadow: none;
    }
    &-target {
      background: transparent;
      border: 0;
      box-shadow: none;
    }
    &-base {
      display: flex;
      align-items: center;
      height: r(24);
      border-left: 2px solid $gray;
      border-right: 2px solid $gray;
    }
    &-pips {
      display: flex;
      justify-content: space-between;
      height: auto;
      position: static;
      &-horizontal {
        padding: r(17) 0 0;
      }
    }
    &-connect {
      background: $black;
    }
    &-connects {
      height: r(2);
      background: #939393;
      border-radius: 0;
    }
    &-value {
      position: static;
      font-size: r(18);
      line-height: 1.4;
      color: $gray;
      &-horizontal {
        transform: none;
      }
    }
    &-tooltip {
      padding: 0 0 r(8);
      font-size: r(18);
      line-height: 1.4;
      color: $gray;
      border: 0;
      background: transparent;
    }
  }
}

@media (max-width: 1279px) {
  .range-slider {
    .noUi {
      &-base {
        height: r(11);
      }
      &-horizontal {
        .noUi {
          &-handle {
            width: r(25);
            height: r(25);
            right: r(-12);
            top: r(-8);
            border-width: 3px;
          }
        }
      }
      &-pips {
        padding: r(4) 0 0;
      }
      &-value {
        font-size: r(14);
      }
      &-tooltip {
        padding: 0 0 r(4);
        font-size: r(14);
      }
    }
  }
}
