.kuula-section{
    margin: r(100) 0;
    iframe{
        height: r(640) !important;
    }

    @media(max-width: 1279px){
        iframe{
            height: r(550) !important;
        }
    }
}