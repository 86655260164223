.image-page-head {
  height: r(785);
  margin-bottom: r(100);
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .breadcrumbs {
    width: 44%;
    margin-top: r(40);
  }
  .title {
    margin: auto 0 auto r(150);
    font-size: r(74);
    line-height: 1.3;
    font-weight: 500;
  }
  .desc {
    margin: auto 0 0 r(150);
  }
  .title,
  .desc {
    width: 32.2%;
  }
  .img {
    width: r(870);
    height: r(785);
    margin-left: auto;
    flex: 0 0 auto;
  }
}

@media (max-width: 1279px) {
  .image-page-head {
    height: auto;
    padding: r(24) 0 0;
    margin: 0;
    .title,
    .desc {
      width: 100%;
      margin: 0;
      order: 1;
    }
    .title {
      margin-bottom: r(12);
      font-size: r(42);
    }
    .container {
      height: auto;
    }
    .breadcrumbs {
      width: 100%;
      margin: 0 0 r(40);
    }
    .img {
      margin-bottom: r(30);
      width: 100%;
      height: auto;
    }
  }
}
