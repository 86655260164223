.services-list {
  &-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: $gray;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: r(4);
      bottom: r(1);
      z-index: -1;
    }
    &:not(:last-child) {
      margin-bottom: r(10);
    }
    &:hover {
      color: $white;
      .link-cover {
        color: $white;
      }
      &::before {
        background: $red;
      }
    }
  }
  &-name {
    flex: 0 0 80%;
  }
  &-num {
  }
}

@media (max-width: 1279px) {
  .services-list {
    &-item {
      font-size: r(14) !important;
    }
    &-name {
      flex: 0 0 88%;
    }
  }
}
