.second-screen {
  .top {
    min-height: r(1152);
    padding: r(325) 0 r(50);
    position: relative;
    overflow: hidden;
  }
  .title {
    margin: 0 0 r(31);
    font-size: r(126);
    line-height: 0.25;
    font-weight: 100;
    span {
      font-family: "Cormorant Infant", serif;
      font-size: r(162);
      line-height: 1.3;
      color: $red;
      font-style: italic;
      font-weight: 600;
    }
  }
  .content-text {
    color: $gray;
  }
  .items-grid {
    width: 86.75%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(60) r(160);
    .name {
      margin-bottom: r(26);
      font-size: r(24);
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .marquee {
    display: flex;
    padding: r(65) 0 r(90);
    overflow: hidden;
    .flow {
      display: flex;
      align-items: center;
      animation: flow 140s linear 0s infinite normal forwards;
    }
    .word {
      flex: 0 0 auto;
      font-size: r(130);
      line-height: 1.3;
      font-weight: 500;
      filter: url(#stroke-text-svg-filter);
      transition: transform $tr-time;
      &:hover {
        filter: none;
        color: $red;
        transform: scale(1.05) skew(-8deg);
      }
    }
    .separator {
      width: r(120);
      height: r(12);
      margin: 0 r(51);
      flex: 0 0 auto;
      border: 1px solid $black;
    }
  }
  .blur {
    width: r(779);
    height: r(630);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    object-fit: contain;
  }
}

@keyframes flow {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1279px) {
  .second-screen {
    .top {
      min-height: auto;
      padding: r(60) 0;
    }
    .blur {
      display: none;
    }
    .content-text {
      width: 100%;
    }
    .title {
      font-size: r(38);
      line-height: 0.8;
      span {
        font-size: 1.25em;
        line-height: 0.7;
      }
    }
    .marquee {
      padding: r(15) 0 r(22);
      .separator {
        width: r(38);
        height: r(5);
        margin: 0 r(16);
        border-width: 1px;
      }
      .word {
        font-size: r(52);
      }
    }
    .items-grid {
      width: 100%;
      grid-template-columns: 1fr;
      gap: r(40);
      .name {
        margin-bottom: r(10);
        font-size: r(21);
      }
    }
  }
}
