.order-project-section {
  padding: 0 0 r(40);
  .section-text {
    width: 50%;
    margin: r(30) 0 0 r(52);
  }
  .option-row {
    margin: r(120) 0 r(160);
    .desc {
      max-width: 62%;
      margin-bottom: r(30);
    }
    .row-wrap {
      margin: r(16) r(52) 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .buttons-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 r(-38) r(-28) 0;
    &.alt {
      width: 66.8%;
      .btn {
        display: inline-block;
        flex: 1 1 auto;
        text-align: left;
      }
    }
    .btn {
      margin: 0 r(38) r(28) 0;
      --border-color: #{$black};
      --text-color: #{$black};
      --bg-color: transparent;
      span {
        margin-left: r(4);
        text-transform: none;
        color: rgba($black, 0.5);
      }
      &[data-active] {
        --bg-color: #{$black};
        --border-color: #{$black};
        --text-color: #{$white};
        span {
          color: inherit;
        }
      }
    }
  }
  .form {
    width: 70.25%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(42);
  }
  .range-slider {
    width: 59.7%;
    margin-top: r(69);
  }
}

@media (max-width: 1279px) {
  .order-project-section {
    padding: 0 0 r(10);
    overflow: hidden;
    .section-text {
      width: 100%;
      margin: r(18) 0 0;
    }
    .buttons-row {
      margin: 0 0 r(-14);
      .btn {
        flex: 0 0 100%;
        margin: 0 0 r(14);
      }
      &.alt {
        width: 100%;
      }
    }
    .range-slider {
      width: 100%;
      margin-top: r(62);
    }
    .option-row {
      margin: r(60) 0;
      .row-wrap {
        margin: r(10) 0 0;
      }
      .desc {
        max-width: 74.2%;
      }
    }
    .form {
      width: 100%;
      grid-template-columns: 1fr;
      gap: r(14);
      .submit {
        margin-top: r(8);
      }
      .personal {
        text-align: center;
      }
    }
  }
}
