.content-image {
	img {
		max-width: 100%;
		margin: 0 auto;
	}
}

@media (max-width: 1279px) {
	.content-image {
	}
}
