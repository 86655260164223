.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.collapse {
  overflow: hidden;
  transition: height var(--collapse-time, #{$tr-time * 2});
  &.is-collapsed {
    height: 0;
  }
}

.link-cover {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: r(19) r(32);
  text-align: center;
  font-size: r(20);
  text-transform: uppercase;
  color: var(--text-color) !important;
  text-decoration: none !important;
  border: 1px solid var(--border-color);
  background: var(--bg-color);
  cursor: pointer;
  &-white-border {
    --border-color: #{$white};
    --text-color: #{$white};
    --bg-color: transparent;
    &:hover {
      --text-color: #{$black};
      --bg-color: #{$white};
    }
  }
  &-black-border {
    --border-color: #{$black};
    --text-color: #{$black};
    --bg-color: transparent;
    &:hover {
      --border-color: #{$black};
      --text-color: #{$white};
      --bg-color: #{$black};
    }
  }
  &-black {
    --bg-color: #{$black};
    --border-color: #{$black};
    --text-color: #{$white};
    &:hover {
      --text-color: #{$black};
      --bg-color: #{$white};
    }
  }
  .preloader,
  .ok-icon {
    display: none;
  }
  &:not(:disabled) {
    transition-duration: $tr-time;
    transition-property: color, border-color, background-color, box-shadow;
  }
  &:disabled,
  &.htmx-request,
  &.success {
    pointer-events: none;
    transition-duration: 0ms;
    --text-color: transparent;
  }
  &.htmx-request {
    .preloader {
      display: block;
    }
  }
  &.success {
    .ok-icon {
      display: block;
    }
  }
}

@media (max-width: 1279px) {
  .btn {
    padding: r(8) r(18);
    font-size: r(14);
  }
}
