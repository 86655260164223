.content-gallery {
  display: grid;
  gap: r(30);
  --gallery-item-padding: 59%;
  &.cols-1 {
    justify-content: center;
    grid-template-columns: 77.2%;
  }
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1279px) {
  .content-gallery {
    gap: r(30) r(10);
  }
}
