.breadcrumbs {
  .name {
    &::before {
      content: "[ ";
    }
    &::after {
      content: " ]";
    }
  }
  .item {
    display: inline;
    line-height: 1.6;
    color: var(--breadcrumb-color);
    &:last-child {
      .name {
        &::before,
        &::after {
          color: $red;
        }
      }
      color: var(--breadcrumb-active-color);
    }
  }
}
