.instagram-icon {
  .icon {
    width: r(42);
    height: r(42);
    fill: var(--icon-color);
  }
  &:hover {
    .icon {
      fill: var(--icon-hover-color);
    }
  }
}

@media (max-width: 1279px) {
  .instagram-icon {
    .icon {
      width: r(32);
      height: r(32);
    }
  }
}
