.page-head {
  padding: r(42) 0 r(60);
  --breadcrumb-color: #{$gray};
  --breadcrumb-active-color: #{$black};
}

@media (max-width: 1279px) {
  .page-head {
    padding: r(24) 0 r(40);
  }
}
