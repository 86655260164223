.footer {
  padding: r(120) 0 r(40);
  .bottom {
    margin-top: r(92);
    color: #bdbdbd;
    .link {
      margin-bottom: r(27);
      text-decoration: underline;
    }
  }
  .form-col {
    flex: 0 0 auto;
    --form-border-color: #{$white};
    --form-text-color: #{$black};
    --form-bg-color: #{$white};
    --form-error-color: #{$red};
    --form-placeholder-active-color: #6d6d6d;
    --form-placeholder-color: #6d6d6d;
    --form-status-icon-color: #{$white};
    --form-personal-color: #{$white};
    --form-personal-link-color: #{$white};
    .form-input-wrap {
      margin: r(60) 0 r(40);
    }
    .title {
      margin-bottom: r(20);
    }
    .personal {
      margin-top: r(10);
    }
  }
  .menu-col {
    flex: 0 0 35%;
    --icon-color: #{$white};
    --icon-hover-color: #{$red};
  }
  .menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(20);
    margin-bottom: r(82);
  }
  .social-contact {
    margin-right: r(45);
  }
  .top {
    margin-bottom: r(112);
  }
  .marquee {
    display: flex;
    overflow: hidden;
    .flow {
      display: flex;
      animation: flow 70s linear 0s infinite normal forwards;
    }
    .logo {
      width: r(1397);
      height: r(177);
      margin-left: r(40);
      flex: 0 0 auto;
      fill: $white;
    }
  }
}

@media (max-width: 1279px) {
  .footer {
    padding: r(60) 0;
    .top {
      margin-bottom: r(60);
    }
    .bottom {
      margin-top: r(61);
      .link {
        margin-bottom: r(24);
      }
    }
    .menu-col {
      flex: 0 0 auto;
    }
    .form-col {
      padding: r(62) 0 0;
      order: 1;
      .form-input-wrap {
        margin: r(30) 0 r(21);
      }
      .submit {
        width: 100%;
      }
    }
    .menu {
      margin-bottom: r(62);
      gap: r(14) r(65);
    }
    .social-contact {
      margin-right: r(35);
    }
    .marquee {
      .logo {
        width: r(337);
        height: r(40);
        margin-left: r(9);
      }
    }
  }
}
