.vacancies-section {
  .photo {
    width: 100%;
    margin-bottom: r(24);
  }
  .name,
  .phone {
    margin-bottom: r(12);
  }
  .position {
    margin-bottom: r(24);
  }
}

@media (max-width: 1279px) {
  .vacancies-section {
    .photo {
      margin-bottom: r(30);
    }
    .name {
      margin-bottom: r(10);
    }
    .phone,
    .position {
      margin-bottom: r(20);
    }
  }
}
