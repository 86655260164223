.vacancy {
  padding: r(30) 0 r(16);
  li {
    color: $gray;
  }
}

@media (max-width: 1279px) {
  .vacancy {
    padding: r(12) 0 r(26);
  }
}
