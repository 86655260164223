.order-project-btn {
  position: fixed;
  right: 0;
  top: 40%;
  padding: r(18) r(8);
  z-index: 11;
  transform: rotate(180deg);
  color: $white;
  font-size: r(14);
  writing-mode: vertical-lr;
  text-transform: uppercase;
  background: $black;
  border: 1px solid $white;
  border-left: 0;
}

@media (min-width: 1280px) {
  .order-project-btn {
    display: none;
  }
}
