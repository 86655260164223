.content-text-image {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .img {
    width: 49%;
    flex: 0 0 auto;
  }
  .content-text {
    flex: 0 0 43.4%;
  }
  &.right-image {
    .img {
      order: 1;
    }
  }
}

@media (max-width: 1279px) {
  .content-text-image {
    .img {
      width: 52%;
    }
  }
}

@media (max-width: 767px) {
  .content-text-image {
    flex-direction: column;
    &.right-image {
      .img {
        order: 0;
      }
    }
    .img {
      width: 100%;
      align-self: center;
      max-width: 100%;
      max-height: r(340);
      margin-bottom: r(20);
      flex: 0 0 auto;
    }
  }
}
