.content-kuula-embed{
    iframe{
        height: r(640) !important;
    }

    @media(max-width: 1279px){
        iframe{
            height: r(550) !important;
        }
    }
}