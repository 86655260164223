.patronage-gallery-grid {
  gap: r(30);
  &:not(:last-child) {
    margin-bottom: r(120);
  }
  &.single-col {
    --gallery-item-padding: 49.4%;
  }
  &.double-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    --gallery-item-padding: 74.05%;
  }
  &.triple-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    --gallery-item-padding: 74.05%;
  }
}

@media (max-width: 1279px) {
  .patronage-gallery-grid {
    &:not(:last-child) {
      margin-bottom: r(60);
    }
    &.double-col,
    &.triple-col {
      grid-template-columns: 1fr;
      --gallery-item-padding: 49.4%;
    }
  }
}
