.categories-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: r(40);
  .link {
    display: flex;
    flex: 0 0 auto;
    &::after {
      content: "]";
      margin-left: r(12);
    }
    &::before {
      content: "[";
      margin-right: r(12);
    }
    &:hover {
      color: $black;
      &::before,
      &::after {
        color: $red;
      }
    }
    &.active {
      &::after,
      &::before {
        color: $red;
      }
    }
  }
}

@media (max-width: 1279px) {
  .categories-list {
    margin: 0 r(-25) 0;
    padding: 0 r(25) r(15);
    overflow: auto;
    .link {
      &:not(:last-child) {
        margin-right: r(6);
      }
    }
  }
}
