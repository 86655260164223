.content-quote {
	padding: r(13) 0 r(13) r(44);
	border-left: 3px solid $red;
	.text {
		font-size: r(22);
		line-height: 1.4;
	}
	.fio {
		margin-top: r(15);
		font-size: r(18);
		line-height: 1.4;
		color: #908fa8;
	}
}

@media (max-width: 1279px) {
	.content-quote {
		padding: r(8) 0 r(8) r(22);
		.text {
			font-size: r(14);
		}
		.fio {
			margin-top: r(8);
			font-size: r(12);
		}
	}
}
