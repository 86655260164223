.text-page-head {
  padding: r(40) 0 0;
  margin-bottom: r(60);
  .title {
    width: 70%;
  }
  .breadcrumbs {
    margin-bottom: r(129);
  }
  .text-col {
    width: 66.1%;
    margin: 0 r(150) 0 auto;
  }
  .text-row {
    display: flex;
    justify-content: space-between;
    margin-top: r(150);
  }
  .text1 {
    flex: 0 0 48.8%;
  }
  .text2 {
    flex: 0 0 35.9%;
  }
}

@media (max-width: 1279px) {
  .text-page-head {
    padding: r(24) 0 0;
    margin: 0 0 r(30);
    .breadcrumbs {
      margin-bottom: r(40);
    }
    .text-col {
      width: 100%;
      margin: 0;
    }
    .title {
      width: 100%;
      font-size: r(32);
    }
    .text-row {
      margin-top: r(12);
      flex-wrap: wrap;
    }
    .text1,
    .text2 {
      flex: 0 0 74.2%;
    }
    .text2 {
      margin: r(30) 0 0 auto;
    }
  }
}
