.whatsapp-icon {
  display: inline-block;
  .icon {
    width: r(51);
    height: r(51);
    fill: var(--icon-color);
  }
  &:hover {
    .icon {
      fill: var(--icon-hover-color);
    }
  }
}

@media (max-width: 1279px) {
  .whatsapp-icon {
    .icon {
      width: r(35);
      height: r(35);
    }
  }
}
