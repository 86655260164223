.projects-section {
  padding: r(120) 0 0;
  .title {
    margin-bottom: r(60);
  }
  .tab-btn {
    display: flex;
    &::before,
    &::after {
      font-size: 0.83em;
      line-height: 1.5;
      font-weight: 600;
      transition: color $tr-time;
    }
    &::before {
      content: "[";
      margin-right: r(12);
    }
    &::after {
      content: "]";
      margin-left: r(12);
    }
    &:hover,
    &.active {
      &::before,
      &::after {
        color: $red;
      }
    }
  }
  .img-col {
    --link-hover-color: #{$white};
    overflow: hidden;
    &:first-child {
      padding: r(30) r(30) r(30) 0;
      .name {
        left: r(75);
      }
    }
    &:nth-child(4) {
      padding: r(30) r(30) r(30) 0;
    }
    &:nth-child(6) {
      padding: r(30) 0 r(30) r(30);
    }
    &:nth-child(9) {
      padding: r(30) 0 r(30) r(30);
    }
    .name {
      position: absolute;
      top: r(30);
      left: r(30);
      z-index: 1;
      max-width: 80%;
      opacity: 0;
      transform: translateY(-100%);
      transition-duration: $tr-time;
      transition-property: transform, opacity;
    }
    .img {
      width: 100%;
      height: 100%;
      transition: transform 0.9s;
    }
    .img-wrap {
      position: relative;
      height: r(488);
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #000 -28.38%,
          rgba(0, 0, 0, 0) 42.3%
        );
      }
      &:hover {
        .img {
          transform: scale(1.1);
        }
        .name {
          opacity: 1;
          transform: none;
        }
      }
    }
  }
  .text-col {
    position: relative;
    padding: r(30);
    .link {
      display: inline-flex;
      align-items: center;
      margin-top: r(36);
      &:hover {
        .arr {
          fill: var(--link-hover-color);
        }
      }
      .arr {
        width: r(20);
        height: r(7);
        margin-left: r(16);
        fill: $gray;
      }
    }
  }
  .all-link-col {
    padding: r(30);
    .all-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: r(488);
      position: relative;
      padding: r(30);
      border: 1px solid $red;
      background: $red;
      color: $white;
      transition: background-color $tr-time;
      .arr {
        width: r(57);
        height: r(16);
        align-self: flex-end;
        fill: $white;
      }
      &:hover {
        background: transparent;
        color: $red;
        .arr {
          fill: $red;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .projects-section {
    .img-col {
      &:first-child {
        grid-column: span 2;
      }
      &:nth-child(6) {
        grid-column: span 2;
      }
    }
    .text-col {
      &:nth-child(7) {
        display: none;
      }
      &:nth-child(10) {
        grid-column: 3/4;
        grid-row: 3/4;
      }
    }
    .all-link-col {
      grid-row: 3/4;
      grid-column: 2/3;
    }
  }
}

@media (max-width: 1279px) {
  .projects-section {
    padding: r(60) 0 0;
    .title {
      margin-bottom: r(40);
    }
    .btns-row {
      gap: r(11);
      overflow: auto;
      margin-left: r(-25);
      margin-right: r(-25);
      padding: 0 r(25);
    }
    .tab-btn {
      font-size: r(14);
      &::before {
        margin-right: r(2);
      }
      &::after {
        margin-left: r(2);
      }
    }
    .img-col {
      padding: r(25) r(30) !important;
      .img-wrap {
        height: r(234);
      }
      &:first-child {
        .name {
          left: r(12);
          top: r(24);
        }
      }
      &:nth-child(4) {
        .img-wrap {
          height: r(352);
        }
      }
      &:nth-child(9) {
        .img-wrap {
          height: r(352);
        }
      }
    }
    .text-col {
      padding: r(30) r(25);
      .link {
        margin-top: r(30);
        font-size: r(14);
      }
      &:nth-child(10) {
        display: none;
      }
    }
    .img-col,
    .text-col {
      &:first-child {
        border-top: 0 !important;
      }
    }
    .empty-col {
      display: none;
    }
    .all-link-col {
      padding: r(30) r(25);
      .all-link {
        height: r(164);
        padding: r(24) r(12);
      }
    }
  }
}
