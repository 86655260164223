.preloader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: r(57);
	height: r(57);
	margin: auto;
	transform: scale(var(--form-status-scale));
	&__item {
		position: absolute;
		width: r(13);
		height: r(13);
		background-color: var(--form-status-icon-color);
		border-radius: 50%;
		animation: bounce_circularG 1.1s infinite;
		&-1 {
			left: 0;
			top: r(23);
			animation-delay: 0s;
		}

		&-2 {
			left: r(6);
			top: r(6);
			animation-delay: 0.14s;
		}

		&-3 {
			top: 0;
			left: r(23);
			animation-delay: 0.28s;
		}

		&-4 {
			right: r(6);
			top: r(6);
			animation-delay: 0.42s;
		}

		&-5 {
			right: 0;
			top: r(23);
			animation-delay: 0.56s;
		}

		&-6 {
			right: r(6);
			bottom: r(6);
			animation-delay: 0.7s;
		}

		&-7 {
			left: r(23);
			bottom: 0;
			animation-delay: 0.84s;
		}

		&-8 {
			left: r(6);
			bottom: r(6);
			animation-delay: 0.98s;
		}
	}
}

@keyframes bounce_circularG {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.3);
	}
}
