.post-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  &.img-left {
    .img-col {
      grid-column: 1/3;
      &.small-img {
        grid-column: 2/3;
      }
    }
  }
  &.img-right {
    .img-col {
      grid-column: 3/5;
      &.small-img {
        grid-column: 3/4;
      }
    }
    .text-col {
      grid-row: 1/2;
      grid-column: 2/3;
      border-left-width: 2px;
    }
  }
  &:first-child {
    border-top-width: 2px;
  }
  &:last-child {
    border-bottom-width: 2px;
  }
  .img-col {
    border-right: 1px solid $light-gray;
    .img {
      width: 100%;
      height: r(480);
      min-height: 100%;
      object-fit: cover;
    }
  }
  .text-col {
    display: flex;
    flex-direction: column;
    padding: r(50) r(40) r(52);
    border-left: 1px solid $light-gray;
    border-right: 2px solid $light-gray;
    .desc {
      color: $gray;
      &.top {
        margin-bottom: r(24);
      }
      &.bottom {
        margin-top: r(24);
        order: 1;
      }
    }
    .link {
      display: flex;
      align-items: center;
      margin-top: auto;
      padding-top: r(30);
      order: 3;
      &:not(:hover) {
        color: $gray;
      }
      .arr {
        width: r(20);
        height: r(8);
        margin-left: r(16);
        fill: $gray;
      }
      &:hover {
        .arr {
          fill: var(--link-hover-color);
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .post-row {
    grid-template-columns: 1fr;
    border-top: 0;
    border-bottom-width: 1px;
    .img-col,
    .text-col {
      grid-column: auto !important;
      grid-row: auto !important;
    }
    .img-col {
      border: 0;
      .img {
        height: auto;
      }
    }
    .text-col {
      padding: r(30) r(25);
      border: 0;
      .desc {
        &.bottom {
          margin-top: r(12);
        }
        &.top {
          margin-bottom: r(12);
        }
      }
      .link {
        margin-top: r(30);
      }
    }
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom-width: 1px;
    }
    &:not(:last-child) {
      margin-bottom: r(30);
    }
  }
}
