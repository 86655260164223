.patronage-about {
  .main-img {
    width: 100%;
    margin-bottom: r(90);
  }
  .description {
    display: flex;
  }
  .more-detailed {
    flex: 0 0 32.2%;
    color: $gray;
  }
  .text-col {
    margin-left: r(30);
    flex: 0 0 49.2%;
  }
  .quote {
    margin-top: r(80);
  }
  &:not(:last-child) {
    margin-bottom: r(120);
  }
}

@media (max-width: 1279px) {
  .patronage-about {
    .description {
      display: block;
    }
    .main-img {
      margin-bottom: r(30);
    }
    .more-detailed {
      margin-bottom: r(10);
      font-size: r(12);
    }
    .text-col {
      margin: 0;
    }
    .quote {
      margin-top: r(40);
      font-size: r(18);
    }
    &:not(:last-child) {
      margin-bottom: r(87);
    }
  }
}
