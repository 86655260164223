.header {
  position: relative;
  padding: r(41) 0;
  background: var(--bg-color);
  border-bottom: 2px solid var(--border-color);
  .logo {
    .icon {
      width: r(244);
      height: r(36);
      fill: var(--content-color);
    }
  }
  > .container {
    .btn {
      margin: 0 r(30) 0 0;
      --border-color: var(--content-color);
      --text-color: var(--content-color);
      --bg-color: transparent;
    }
  }
  .action {
    margin-left: r(30);
    &:hover {
      color: $red;
      .icon {
        fill: $red;
      }
    }
  }
  .lang-switch {
    font-size: r(24);
    font-weight: 600;
    color: var(--content-color);
    text-decoration: underline;
    text-transform: uppercase;
  }
  .search {
    .icon {
      width: r(30);
      height: r(30);
      fill: var(--content-color);
    }
  }
  .whatsapp {
    .icon {
      width: r(31);
      height: r(31);
      fill: var(--content-color);
    }
  }
  &.black {
    --bg-color: #{$black};
    --content-color: #{$white};
    --border-color: rgba(233, 232, 233, 0.06);
    .btn {
      &:hover {
        --border-color: #{$white};
        --text-color: #{$black};
        --bg-color: #{$white};
      }
    }
  }
  &.white {
    --bg-color: #{$white};
    --content-color: #{$black};
    --border-color: #{$light-gray};
    .btn {
      &:hover {
        --border-color: #{$black};
        --text-color: #{$white};
        --bg-color: #{$black};
      }
    }
  }
  .open-menu {
    display: none;
    width: r(23);
    height: r(6);
    position: relative;
    margin-left: r(20);
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--content-color);
      transition: background-color $tr-time, transform $tr-time;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    &.open {
      &::before {
        transform: translate(0, r(3)) rotate(45deg);
      }
      &::after {
        transform: translate(0, r(-2)) rotate(-45deg);
      }
    }
  }
}

@media (max-width: 1279px) {
  .header {
    padding: r(23) 0;
    border-bottom-width: 1px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: var(--border-color);
    }
    &::before {
      left: r(25);
    }
    &::after {
      right: r(25);
    }
    .mob-wrap {
      padding: 0 r(11);
    }
    .open-menu {
      display: block;
    }
    .logo {
      .icon {
        width: r(157);
        height: r(22);
      }
    }
    .btn {
      display: none;
    }
    .lang-switch {
      font-size: r(12);
    }
    .search {
      .icon {
        width: r(15);
        height: r(15);
      }
    }
    .whatsapp {
      .icon {
        width: r(16);
        height: r(16);
      }
    }
    .action {
      margin-left: r(16);
    }
  }
}
