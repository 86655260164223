.blog-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: r(40) r(30);
  min-height: r(688);
  background: $gray;
  .category {
    display: inline-block;
    margin-bottom: r(40);
    padding-bottom: r(6);
    border-bottom: 1px solid $white;
  }
  .bg {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.8);
    }
  }
  .arr {
    width: r(57);
    height: r(16);
    fill: $white;
  }
  &:hover {
    .link-cover {
      color: $white;
      text-decoration: underline;
    }
  }
}

@media (max-width: 1279px) {
  .blog-card {
    padding: r(23) r(12);
    min-height: r(395);
    .category {
      margin-bottom: r(19);
    }
    .arr {
      width: r(34);
      height: r(8);
    }
  }
}
