.search-form {
  height: r(148);
  padding: r(41) 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: $white;
  border-bottom: 2px solid $light-gray;
  --form-border-color: transparent;
  --form-text-color: #{$black};
  --form-bg-color: #{$white};
  --form-error-color: #{$red};
  --form-placeholder-active-color: #{$gray};
  --form-placeholder-color: #{$gray};
  --form-status-icon-color: #{$black};
  .container {
    display: flex;
    align-items: center;
  }
  .close {
    .icon {
      width: r(24);
      height: r(24);
      fill: $black;
    }
  }
  .submit {
    margin-right: r(60);
  }
  .form-input-wrap {
    flex: 1 0 auto;
  }
  .form-placeholder {
    display: flex;
    align-items: center;
    .icon {
      width: r(30);
      height: r(30);
      margin-left: r(12);
      fill: var(--form-placeholder-color);
    }
  }
}

@media (max-width: 1279px) {
  .search-form {
    height: auto;
    position: static;
    padding: 0;
    border-bottom: 0;
    .container {
      flex-wrap: wrap;
      padding: 0;
    }
    .submit {
      margin: 0;
      flex: 0 0 100%;
      order: 1;
      border: 0;
      border-top: 1px solid $light-gray;
    }
    .close {
      margin-right: r(38);
      .icon {
        width: r(17);
        height: r(17);
      }
    }
    .form-placeholder {
      .icon {
        width: r(15);
        height: r(15);
        margin-left: r(8);
      }
    }
    .form-input-wrap {
      margin: r(14) r(22);
    }
  }
}
