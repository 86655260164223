.work-steps-section {
  padding: r(120) 0 0;
  border-top: 1px solid $light-gray;
  .title {
    margin-bottom: r(60);
  }
  .work-steps {
    padding: r(120) 0;
    position: relative;
    .horizontal-line {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 2px;
      background: rgba($black, 0.1);
      .line {
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        transform-origin: left center;
        background: $black;
      }
    }
    .vertical-line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 2px;
      margin: 0 auto;
      background: rgba($black, 0.1);
      .line {
        width: 100%;
        height: 100%;
        transform: scaleY(0);
        transform-origin: top center;
        background: $black;
      }
    }
  }
  .steps-ok {
    width: r(97);
    height: r(97);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    stroke: $black;
    fill: transparent;
    opacity: 0.1;
  }
  .step-row {
    display: flex;
    justify-content: space-between;
    padding: 0 r(150);
    opacity: 0.1;
    .img-col,
    .text-col {
      flex: 0 0 37.65%;
    }
    .img {
      width: 100%;
      transition: filter $tr-time;
      &:not(:hover) {
        filter: saturate(0);
      }
    }
    .text-col {
      align-self: center;
    }
    &-wrap {
      &:not(:last-child) {
        margin-bottom: r(120);
      }
    }
    &.img-right {
      .img-col {
        order: 1;
      }
    }
  }
}

@media (max-width: 1279px) {
  .work-steps-section {
    padding: r(60) 0 r(70);
    .title {
      width: 70%;
      margin-bottom: r(40);
    }
    .step-row {
      padding: r(20) 0;
      flex-direction: column;
      &-wrap {
        position: relative;
        z-index: 1;
        background: $white;
        &:not(:last-child) {
          margin-bottom: r(117);
        }
      }
      .img {
        filter: none !important;
      }
      .img-col {
        margin-bottom: r(20);
      }
      .img-col,
      .text-col {
        flex: 0 0 100%;
      }
      &.img-right {
        .img-col {
          order: 0;
        }
      }
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .steps-ok {
      width: r(70);
      height: r(70);
    }
    .work-steps {
      padding: r(75) 0 r(117);
    }
  }
}
