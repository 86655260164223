.accordion {
  padding: r(24) 0 r(24);
  margin-bottom: -2px;
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__name {
    flex: 0 0 70%;
  }
  &__arrow {
    width: r(24);
    height: r(22);
    flex: 0 0 auto;
    fill: $black;
    transition-property: transform, stroke;
  }
  &__collapse {
    overflow: hidden;
    transition: height var(--collapse-time, #{$tr-time * 2});
    &.is-collapsed {
      height: 0;
    }
  }
  &.open {
    .accordion {
      &__arrow {
        transform: rotate(-180deg);
      }
    }
  }
}

@media (max-width: 1279px) {
  .accordion {
    padding: r(14) 0;
    margin-bottom: -1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    &__name {
      flex: 0 0 85%;
      font-size: r(16);
    }
    &__arrow {
      width: r(11);
      height: r(9);
    }
    &__content {
      width: 100%;
      padding-top: r(30);
    }
  }
}
