.case-section {
  padding: r(60) 0;
  &.single-img {
    .content-wrap {
      width: 83%;
    }
    .text-row {
      display: flex;
      justify-content: space-between;
    }
    .case-desc {
      flex: 0 0 38.8%;
    }
  }
  &.to-right {
    .content-wrap {
      margin-left: auto;
    }
  }
  &.double-img {
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: r(30);
    }
    .text-row {
      width: 65.5%;
    }
    .case-desc {
      margin-top: r(40);
    }
  }
  .img {
    width: 100%;
  }
  .text-row {
    margin-top: r(40);
  }
  .case-date {
    margin-top: r(16);
  }
}

@media (max-width: 1279px) {
  .case-section {
    padding: r(30) 0;
    &.single-img {
      .content-wrap {
        width: 100%;
      }
      .text-row {
        width: 92%;
        flex-direction: column;
      }
    }
    &.to-left {
      .img {
        margin-left: r(-25);
      }
    }
    &.to-right {
      .img {
        margin: 0 r(-25) 0 auto;
      }
      .text-row {
        margin-left: auto;
      }
    }
    &.double-img {
      .container {
        grid-template-columns: 1fr;
        gap: r(60);
      }
      .text-row {
        width: 100%;
      }
    }
    .text-row {
      margin-top: r(30);
    }
    .case-date {
      margin-top: r(10);
    }
    .case-desc {
      margin-top: r(20) !important;
    }
  }
}
