.partners-section {
  padding: r(120) 0 r(120);
  --gap: #{r(80)};
  .marquee {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    position: relative;
    overflow: hidden;
    &-wrap {
      display: flex;
      justify-content: center;
    }
    .flow {
      height: r(155);
      display: flex;
      align-items: center;
      padding-right: var(--gap);
      animation: flow 50s linear 0s infinite normal forwards;
      &:nth-child(2) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
      }
    }
    .logo {
      max-height: 100%;
      transition: filter $tr-time;
      &:not(:last-child) {
        margin-right: var(--gap);
      }
      &:not(:hover) {
        filter: saturate(0);
      }
    }
  }
}

@keyframes flow-alt {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1279px) {
  .partners-section {
    padding: r(60) 0;
    .marquee {
      --gap: #{r(30)};
      overflow: visible;
      &-wrap {
        overflow: hidden;
      }
      .flow {
        height: r(50);
      }
    }
  }
}
