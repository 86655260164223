.feedback-section {
  padding: r(120) 0;
  --icon-hover-color: #{$red};
  .title {
    text-align: center;
    margin-bottom: r(40);
  }
  .whatsapp-icon {
    flex: 0 0 auto;
    margin-left: r(30);
  }
  .personal {
    margin-left: auto;
  }
  .submit-row {
    grid-column: span 2;
  }
  .message {
    grid-row: span 2;
  }
  .form {
    display: grid;
    gap: r(42);
    grid-template-columns: 48.5% 1fr;
    align-items: start;
    --icon-color: #{$black};
  }
  .logo {
    width: r(350);
    height: r(49);
    margin-bottom: r(31);
    fill: $black;
  }
  .contact {
    display: block;
    &:not(:last-child) {
      margin-bottom: r(16);
    }
  }
}

@media (max-width: 1279px) {
  .feedback-section {
    padding: r(60) 0;
    .title {
      margin-bottom: r(10);
      text-align: left;
    }
    .left-col {
      margin: r(60) 0 0;
      order: 1;
    }
    .logo {
      width: r(326);
      height: r(45);
      margin-bottom: r(29);
    }
    .form {
      grid-template-columns: 1fr;
      gap: r(15);
    }
    .submit-row {
      flex-wrap: wrap;
      grid-column: auto;
      margin-top: r(6);
      .submit {
        flex: 1 1 auto;
      }
      .whatsapp-icon {
        margin-left: r(23);
      }
      .personal {
        text-align: center;
        margin-top: r(14);
      }
    }
    .message {
      grid-row: 3/4;
    }
    .contact {
      text-align: center;
      &:not(:last-child) {
        margin-bottom: r(13);
      }
    }
  }
}
