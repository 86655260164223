.main-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100dvh;
  z-index: 9;
  background: $white;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  overflow: auto;
  transition-property: opacity, transform;
  transition-duration: $tr-time;
  &:not(.open) {
    pointer-events: none;
    transform: translate(0, -5%);
    opacity: 0;
  }
  .img {
    position: absolute;
    right: 0;
    top: r(138);
    width: r(738);
    height: r(510);
    z-index: 1;
  }
  .close {
    position: absolute;
    top: r(37);
    right: r(420);
    z-index: 2;
    .icon {
      width: r(24);
      height: r(24);
      fill: $black;
    }
  }
  .menu-col {
    position: relative;
    flex: 0 0 33.45%;
    padding: 0 r(36);
    display: flex;
    align-items: center;
    border-right: 2px solid #e6e6e6;
    transition-property: opacity, transform;
    transition-duration: $tr-time;
    .parent-name {
      position: absolute;
      top: r(30);
      left: 0;
      right: 0;
      font-size: r(30);
      line-height: 1.3;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: underline;
      text-align: center;
    }
    .back {
      display: flex;
      align-items: center;
      position: absolute;
      top: r(34);
      left: r(38);
      font-size: r(24);
      line-height: 1.3;
      font-weight: 500;
      .arr {
        width: r(9);
        height: r(15);
        margin-right: r(16);
      }
    }
  }
  .menu-item {
    &:not(:last-child) {
      margin-bottom: r(34);
    }
  }
  .menu-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: r(24);
    line-height: 1.3;
    font-weight: 500;
    > .arr {
      width: r(9);
      height: r(15);
      margin-left: r(16);
      transform: rotate(180deg);
      fill: $black;
    }
    &::before,
    &::after {
      position: absolute;
      top: r(-2);
      color: $red;
      opacity: 0;
      transition: transform 0.3s;
    }
    &::before {
      content: "[";
      left: r(-12);
      transform: translateX(r(-10));
    }
    &::after {
      content: "]";
      right: r(-12);
      transform: translateX(r(10));
    }
    &:hover {
      color: inherit;
      &::before,
      &::after {
        opacity: 1;
        transform: none;
      }
    }
  }
  .submenu-link {
    color: #636363;
    font-size: r(18);
    line-height: 1.4;
    &:hover {
      color: $black;
    }
    &:not(:last-child) {
      margin-bottom: r(40);
    }
  }
  .form-col {
    --form-border-color: #{$black};
    --form-text-color: #{$black};
    --form-bg-color: #{$white};
    --form-error-color: #{$red};
    --form-placeholder-active-color: #6d6d6d;
    --form-placeholder-color: #6d6d6d;
    --form-status-icon-color: #{$white};
    flex: 1 0 auto;
    .top {
      display: flex;
      justify-content: center;
      padding: r(167) 0 r(36);
      border-bottom: 2px solid #e6e6e6;
    }
    .title {
      margin-bottom: r(17);
    }
    .text {
      margin-bottom: r(64);
    }
    .submit {
      margin-top: r(40);
    }
  }
  .logo-stroke {
    width: r(570);
    height: r(80);
    fill: $black;
  }
  .form-wrap {
    padding: r(43) r(106) 0 r(92);
  }
  .content-wrap {
    position: relative;
    flex: 1 0 auto;
  }
}

@media (min-width: 1280px) {
  .main-menu {
    .menu-col {
      .back {
        &.mob {
          display: none;
        }
      }
      .parent-item {
        display: none;
      }
      &.left-col,
      &.right-col {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 33.45%;
        background: $white;
      }
      &.left-col {
        left: 0;
      }
      &.right-col {
        left: 33.45%;
      }
      &:not(.active) {
        &.left-col,
        &.right-col {
          transform: translate(-15%, 0);
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    .form-col {
      &:not(.active) {
        display: none;
      }
    }
  }
}

@media (max-width: 1279px) {
  .main-menu {
    .menu-col {
      display: block;
      flex: 0 0 auto;
      padding: r(24) r(48) r(48);
      border: 0;
      &:not(:first-child) {
        padding-top: r(131);
      }
      .back {
        top: r(24);
        left: r(48);
        font-size: r(16);
        .arr {
          width: r(5);
          height: r(9);
        }
      }
      .parent-name {
        display: none;
      }
      .parent-item {
        position: absolute;
        left: r(48);
        top: r(77);
        display: inline-block;
        &::before,
        &::after {
          position: absolute;
          top: 0;
          color: $red;
          transition: transform 0.3s;
        }
        &::before {
          content: "[";
          left: r(-10);
        }
        &::after {
          content: "]";
          right: r(-10);
        }
        font-size: r(16);
        line-height: 1.3;
        font-weight: 500;
      }
      &:not(.active) {
        display: none;
      }
    }
    &.right-col-active {
      .left-col {
        display: none;
      }
    }
    .form-col {
      flex: 0 0 auto;
      .top {
        padding: r(23) 0;
        border-top: 1px solid $light-gray;
        border-bottom-width: 1px;
      }
      .text {
        margin-bottom: r(30);
      }
      .submit {
        width: 100%;
        margin-top: r(21);
      }
    }
    .menu-link,
    .menu-item,
    .submenu-link {
      &:not(:last-child) {
        margin-bottom: r(14);
      }
    }
    .logo-stroke {
      width: r(279);
      height: r(39);
    }
    .close,
    .img {
      display: none !important;
    }
    .menu-link {
      font-size: r(16);
      > .arr {
        width: r(5);
        height: r(9);
        margin-left: r(8);
      }
    }
    .submenu-link {
      font-size: r(16);
      line-height: 1.3;
    }
    .content-wrap {
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        z-index: 3;
        background: $light-gray;
      }
      &::before {
        left: r(25);
      }
      &::after {
        right: r(25);
      }
    }
    .form-wrap {
      padding: r(24) r(48) r(58);
    }
  }
}
