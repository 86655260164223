.content-video-text {
  .video-wrap {
    position: relative;
    margin-bottom: r(40);
  }
  .content-wrap {
    max-width: r(1178);
    margin: 0 auto;
  }
  .video {
    width: 100%;
    display: block;
  }
  .play {
    height: r(108);
    width: r(108);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .icon {
      width: 100%;
      height: 100%;
      fill: $white;
    }
  }
}

@media (max-width: 1279px) {
  .content-video-text {
    .video-wrap {
      margin-bottom: r(30);
    }
    .play {
      width: r(30);
      height: r(30);
    }
  }
}
