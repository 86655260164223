.search-page {
  .title {
    margin-bottom: r(60);
  }
  .not-found {
    width: 50%;
    margin: 0 0 0 r(52);
  }
}

@media (max-width: 1279px) {
  .search-page {
    .title {
      margin-bottom: r(30);
    }
    .not-found {
      width: 100%;
      margin: 0;
    }
  }
}
