.additional-posts {
  padding: r(120) 0 0;
  .title {
    margin-bottom: r(40);
  }
  .projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 r(60);
    position: relative;
    border-top: 2px solid $light-gray;
    border-bottom: 2px solid $light-gray;
    .col {
      padding: r(30) 0;
    }
    .project {
      height: r(488);
      padding: r(30) r(75);
      position: relative;
      z-index: 1;
      color: $white;
      background: $gray;
      --link-hover-color: #{$white};
      overflow: hidden;
      .name {
        width: 80%;
      }
      .bg {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(
            180deg,
            #000 -17.68%,
            rgba(0, 0, 0, 0) 49.18%
          );
        }
        &__img {
          transition: transform 0.9s;
        }
      }
      &:hover {
        .bg__img {
          transform: scale(1.1);
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 2px;
      background: $light-gray;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1279px) {
  .additional-posts {
    padding: r(60) 0 0;
    .projects {
      grid-template-columns: 1fr;
      border: 0;
      gap: r(28);
      padding: 0 r(25);
      &::after {
        display: none;
      }
      .col {
        padding: 0;
      }
      .project {
        height: r(234);
        padding: r(23) r(12);
        .name {
          width: 100%;
        }
      }
    }
  }
}
