.services-tree {
  position: relative;
  min-height: r(793);
  .img2 {
    width: r(495);
    position: absolute;
    right: 0;
    top: r(140);
  }
  .img1 {
    position: absolute;
    top: r(550);
    right: r(525);
    width: r(300);
  }
}

@media (max-width: 1279px) {
  .services-tree {
    min-height: auto;
    .img1,
    .img2 {
      display: none;
    }
  }
}
