@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}
