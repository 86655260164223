.review-section {
  padding: r(120) 0;
  color: $white;
  background: $black;
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: r(60);
  }
  .title {
    text-transform: uppercase;
  }
  .wrap {
    margin-left: r(150);
  }
  @media (max-width: 1279px) {
    padding: r(60) 0;
    .wrap {
      margin-left: 0;
    }
    .title-row {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: r(40);
    }
    .title {
      margin-bottom: r(15);
    }
  }
}
