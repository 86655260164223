.content-title-underline {
	margin-bottom: r(40);
	padding-bottom: r(12);
	font-size: r(32);
	font-weight: 500;
	line-height: 1.3;
	border-bottom: 2px solid $black;
}

@media (max-width: 1279px) {
	.content-title-underline {
		padding-bottom: r(10);
		margin-bottom: r(20);
		font-size: r(22);
	}
}
