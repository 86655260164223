.service-description {
  padding: r(120) 0 0;
  .text {
    flex: 0 0 39.3%;
    padding-top: r(13);
    span {
      &::before {
        content: "[ ";
      }
      &::after {
        content: " ]";
      }
      &::before,
      &::after {
        color: $red;
      }
    }
  }
  .text-row {
    margin-bottom: r(120);
  }
  .title {
    flex: 0 0 55%;
  }
  .img1,
  .img2 {
    flex: 0 0 auto;
    position: relative;
  }
  .img1 {
    width: 59.5%;
    padding-bottom: 57.9%;
  }
  .img2 {
    width: 39%;
    padding-bottom: 34.4%;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1279px) {
  .service-description {
    padding: r(60) 0;
    .text-row {
      margin-bottom: r(40);
    }
    .title,
    .text {
      flex: 0 0 100%;
    }
    .text {
      margin-top: r(12);
    }
    .img1,
    .img2 {
      width: 48.4%;
    }
    .img1 {
      padding-bottom: 61%;
    }
    .img2 {
      padding-bottom: 42.8%;
    }
  }
}
