.post-card {
  position: relative;
  .img-wrap {
    position: relative;
    padding-bottom: 59.65%;
    background: $light-gray;
  }
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: r(40);
  }
  .section-name {
    flex: 0 0 35%;
    border-bottom: 1px solid $gray;
  }
  .date {
    flex: 1 1 100%;
    text-align: right;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: r(40);
  }
  .name,
  .desc {
    flex: 0 0 59.6%;
  }
}

@media (max-width: 1279px) {
  .post-card {
    .content-wrap {
      margin-top: r(20);
    }
    .section-name {
      margin-bottom: r(26);
    }
    .desc {
      flex: 0 0 75%;
      margin-top: r(10);
    }
    .name {
      flex: 0 0 100%;
      order: 1;
    }
    .bottom {
      margin-top: r(10);
      order: 2;
    }
  }
}
